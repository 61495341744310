import React from 'react'


const Footer = (props) => {


    return (
        <footer>
            <p>Ce site a été crée par <a href="https://www.gobelin-tech.fr/">Gobelin Tech</a></p>
        </footer>
    )
}

export default Footer